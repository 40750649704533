<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Perubahan Modal</h4>
          </div>

          <!-- <button class="btn-add" @click="generatePdf">Export</button>
          <button
            class="btn-add"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            + Tambah
          </button> -->
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center justify-content-end">
            <button class="btn btn-save" :style="{ minWidth: '170px' }" @click="this.showModalRange = true">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.5082 2.125C12.3311 2.125 12.1469 2.19583 12.0124 2.33042L10.7161 3.62667L13.3724 6.28292L14.6686 4.98667C14.9449 4.71042 14.9449 4.26417 14.6686 3.98792L13.0111 2.33042C12.8694 2.18875 12.6924 2.125 12.5082 2.125ZM9.95819 6.38917L10.6099 7.04083L4.19236 13.4583H3.54069V12.8067L9.95819 6.38917ZM2.12402 12.2188L9.95819 4.38458L12.6144 7.04083L4.78027 14.875H2.12402V12.2188Z"
                  fill="#fff" />
              </svg>
              Ubah Parameter
            </button>
          </div>

          <iframe id="pdfFrame" v-if="!isLoading"></iframe>
          <div v-else :style="{
              height: '450px',
              background: '#8f8f8f',
              position: 'relative',
            }">
            <loading :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }" :active="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage" color="#68caf8"
              height="80" width="80"></loading>
          </div>
        </div>
      </section>
    </div>
  </div>
  <modalSingle :onPage="true" :show="showModalRange" :type="'captial_change'" :isMerge="false" @hide="setHide()"
    @update="updateData($event)" :departement="optionDepartement" :isMulti="false" :isEnableYear="false"
    :parameter="parameterReport"></modalSingle>
</template>

<script>
  import SidebarDashboard from "../../../../components/Sidebar.vue";
  import NavbarDashboard from "../../../../components/Navbar.vue";
  import modalSingle from "../../components/report/modalSingle.vue";
  // import Select2 from "vue3-select2-component";
  import {
    jsPDF
  } from "jspdf";
  import "jspdf-autotable";
  import "../../../../assets/font/poppins-bold.js";
  import "../../../../assets/font/poppins-regular.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import Loading from "vue3-loading-overlay";

  import $ from "jquery";
  import {
    get_ReportCapitaChargeStandard
  } from "../../../../actions/report/capital-charge";
  import {
    cksClient
  } from "../../../../helper";
  import moment from "moment";
  import "moment/locale/id";
  import {
    get_ListDivisi
  } from "../../../../actions/master";
import { DATA } from '../../../../constans/Url';
  // import { NONE } from "apisauce";

  // import TableLite from 'vue3-table-lite'
  export default {
    name: "dataDepartement",
    components: {
      SidebarDashboard,
      NavbarDashboard,
      // Select2,
      Loading,
      modalSingle,
    },
    data() {
      return {
        showModalRange: false,
        fullPage: true,
        isLoading: true,
        canvas: null,
        optionDepartement: [],
        dataEstimatedAccount: [],
        parameterReport: {
          id_divisi: this.$route.query.id_divisi,
          date_start: this.$route.query.date_start,
          merge: this.$route.query.merge,
          isAll: this.$route.query.isAll,
        },
        dataCompany: {
          nama: "",
          divisi: "",
          alamat: "",
          email: "",
          no_telp: "",
          logo: "",
        },
        dataReport: [],
        dataHeader: [],
        dataColumn: [],
        titleDate: "",
        titleDateCol: "",
        dateColumn: {},
        namaReport: "",
      };
    },

    mounted() {
      if (this.parameterReport.date_start) {
        this.getData();
      }
      // this.tooltip();
      // this.exportPdf();
    },

    created() {
      this.getDivisi();
    },

    methods: {
      getData() {
        var data = {
          ...this.parameterReport
        };
        data.id_company = cksClient().get("_account").id_company;
        this.isLoading = true;
        get_ReportCapitaChargeStandard(
          data,
          (res) => {
            const {
              dataHeader,
              dataBody,
              divisi,
              periode,
              company
            } = res;

            this.dataCompany = company;
          this.dataCompany.divisi = divisi;

            this.titleDate = periode;

            if (divisi) {
              this.namaReport = divisi;
            } else {
              this.namaReport = cksClient().get("_account").nama_company;
            }
            this.dataReport = dataBody.map((value) => {
              var item = {...value};
              item.nilai = this.formatMoney(value.nilai);
              return item
            });
            this.dataHeader = dataHeader;

            this.isLoading = false;
            this.generatePdf();
          },
          (err) => {
            console.log(err);
          }
        );
      },

      format(date, format = "MMMM YYYY") {
        if (date && date.year && date.month >= 0) {
          var string = date.year + "-" + (date.month + 1) + "-01";
          return `${moment(string).format(format)}`;
        }
      },

      setHide() {
        this.showModalRange = false;
      },

      updateData(event) {
        this.parameterReport = event;
        this.getData();
      },

      getDivisi() {
        get_ListDivisi({
            id_company: cksClient().get("_account").id_company,
          },
          (res) => {
            this.optionDepartement = res.list;
          }
        );
      },
      convertDate(start) {
        this.titleDate = `Periode ${start}`;
      },
      formatMoney(a) {
        if (!a) {
          return 0;
        }
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#6BACCC"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoTwoArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 2);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      // const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const secondArray = wordsArray.slice(arraySize);

      return [firstArray, secondArray];
    },

      async generatePdf() {
        const doc = new jsPDF("p", "mm", "a4");
        var header = "/img/bg-header.png";
        doc.addImage(header, "png", 220, -35, 220, 2500 / 72, null, null, 180);
        doc.addImage(header, "png", 298, -35, 300, 2500 / 72, null, null, 180);
      var logo = await this.convertImgToBase64(
        DATA.COMPANY(this.dataCompany.logo)
      );
        doc.addImage(logo, "png", 13, 6, 20, 20, null, null, 0);

        doc.setFont("Poppins-Bold").setFontSize(13);
        doc.setTextColor(255, 255, 255);
        doc.text(37, 15, `${this.dataCompany.nama}`, {
          align: "left",
        });
        doc.setFont("Poppins-Regular").setFontSize(10);
        doc.setTextColor(255, 255, 255);
        doc.text(37, 22, `${this.dataCompany.divisi ? this.dataCompany.divisi :  this.dataCompany.email}`, {
          align: "left",
        });

        doc.setFontSize(9);
        doc.setFont("Poppins-Regular");
        doc.splitTextToSize(this.dataCompany.alamat,
        10
      );

      const [array1, array2] = this.splitTextIntoTwoArrays(this.dataCompany.alamat, 2)

      doc.text(200, 10, array1.join(" "), {
        align: "right",
      });
      doc.text(200, 16, array2.join(" "), {
        align: "right",
      });
      doc.text(200, 22, this.dataCompany.no_telp, {
        align: "right",
      });
      doc.text(200, 28, this.dataCompany.divisi ? this.dataCompany.email : '', {
        align: "right",
      });
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        // doc.text(105, 45, this.namaReport, "center");
        doc.setFontSize(16);
        doc.text(105, 45, `Perubahan Modal`, {
          align: "center",
        });
        doc.setProperties({
          title: `Perubahan Modal`,
        });
        doc.setFontSize(10);
        doc.text(105, 53, this.titleDate, "center");
        var blankTable = {
          columns: [{
            title: "",
            dataKey: "id",
          }, ],
          rows: [{
            id: ""
          }, ],
        };
        doc.autoTable(blankTable.columns, blankTable.rows, {
          margin: {
            top: 45,
            bottom: 80,
          },
          theme: "plain",
        });
        var tableData = {
          columns: this.dataHeader,
          rows: this.dataReport,
        };
        doc.autoTable(tableData.columns, tableData.rows, {
          showHead: "everyPage",
          didParseCell: function (data) {
            if (data.column.index === 0) {
            var padding = data.row.raw.padding == 0
              ? 1
              : 2;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
            };
          }

            if (data.column.index === 0 && data.row.raw.bold) {
              data.cell.styles.fontStyle = "bold";
            }

            if (data.row.section === "head") {
              data.cell.styles.textColor = "#324C8F";
              // data.cell.styles.halign = "center";
              if (data.column.dataKey === "nilai") {
                data.cell.styles.halign = "right";
              }
            }

            if (data.row.raw.color) {
              data.cell.styles.textColor = data.row.raw.color;
            }
          },

          columnStyles: {
            0: {
              
            },
            1: {
              halign: "right"
            },
          },
          margin: {
            top: 10,
            bottom: 60
          },
          theme: "plain",
        });

        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = doc.internal.getNumberOfPages();
        // var width = doc.internal.pageSize.getWidth();
        // var height = doc.internal.pageSize.getHeight();

        // For each page, print the page number and the total pages
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);

          //Print Page 1 of 4 for example

          var img = new Image();
          img.src = "/img/line.png";
          doc.addImage(img, "png", 190, 250, 170, 1 / 72, null, null, 180);

          doc.text(105, 257, "Finanta Accounting System Report", "center");

          doc.text(
            "Page " + String(i) + " of " + String(pageCount),
            210 - 20,
            315 - 30,
            null,
            null,
            "right"
          );

          doc.text(
            `Tercetak pada ${moment().format("DD MMMM YYYY - HH:mm")}`,
            210 - 192,
            315 - 30,
            null,
            null,
            "left"
          );
        }

        setTimeout(() => {
          var iframe = document.getElementById("pdfFrame");
          iframe.src = doc.output("bloburi", {
            filename: `Neraca ${this.namaReport}.pdf`,
          });
        }, 500);
      },

      tooltip() {
        $('[data-toggle="tooltip"]').tooltip();
      },

      detail(payroll) {
        const getPayroll = payroll;

        if (getPayroll == "bonus") {
          this.$router.push("/data-payroll/detail-bonus");
        }
        if (getPayroll == "gaji") {
          this.$router.push("/data-payroll/detail-gaji");
        }
        if (getPayroll == "thr") {
          this.$router.push("/data-payroll/detail-thr");
        }

        $('[data-toggle="tooltip"]').tooltip("hide");
      },
    },
  };
</script>

<style scoped>
  .card-table {
    margin-top: 0px !important;
  }

  .btn-gaji {
    background-color: #68caf8;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .btn-bonus {
    background-color: #f8ce3a;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .btn-thr {
    background-color: #ff5252;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .dropdown-menu {
    background: transparent !important;
    box-shadow: none !important;
    width: 102px !important;
    min-width: unset;
  }

  .dropdown-item:nth-child(2) {
    margin-top: 6px;
  }

  .dropdown-item:nth-child(3) {
    margin-top: 6px;
  }

  iframe {
    z-index: 1000;
    width: 100%;
    height: 800px;
  }
</style>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>